
import { BackendApi } from "./config/ApiFormatter";

export const GetQuestionSet = async ({ setQuestions }) => {
    const apiUrl =  BackendApi() + "questionSet";

    try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
            throw new Error('Error fetching question set');
        }

        const questionSet = await response.json();
       
        setQuestions(questionSet.data.questions);
    } catch (error) {
        console.error('Error fetching question set:', error);
        throw error; // Rethrow error to handle it in the component
    }
};
