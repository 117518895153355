import { OuterContainer } from "./containers/OuterContainer";

export const RenderStep = (props) => {
    switch (props.currentStep) {
        case 1:
            return (
                <>
                    <OuterContainer case={1} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );
        case 2:
            return (
                <>
                    <OuterContainer case={2} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );

        case 3:
            return (
                <>
                    <OuterContainer case={3} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );
        case 4:
            return (
                <>
                    <OuterContainer case={4} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );
        case 5:
            return (
                <>
                    <OuterContainer case={5} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );

        case 6:
            return (
                <>
                    <OuterContainer case={6} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            )
        case 7:
            return (
                <>
                    <OuterContainer case={7} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );

        case 8:
            return (
                <>
                    <OuterContainer case={8} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );

        case 9:
            return (
                <>
                    <OuterContainer case={9} questions={props.questions} formData={props.formData}
                        setFormData={props.setFormData} isEditing={props.isEditing}
                        setDraftData={props.setDraftData} currentStep={props.currentStep} />
                </>
            );
        default:
            return null;
    }
};