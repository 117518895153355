export const PrivacyContent = () => {
    return <>
        <h3>1. Introduction</h3>
        <p variant="body1" className="dialog-text">
            This Privacy Policy outlines how SkillJourney LLP ("we," "us," "our") collects, uses, and protects your personal information when you use SkillJourney NorthStar, our career guidance platform. By accessing and using our web application, you agree to the terms of this Privacy Policy.
        </p>
        <h3>2. Company Information</h3>
        <p variant="body1" className="dialog-text">
            • Company Name: SkillJourney LLP<br />
            • Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar, Paud Road, Pune 411038<br />
            • Current Address: SkillJourney LLP, 5th Floor, CC&Co, CornerStone Premises, Prabhat Road, Pune 411004<br />
            • Contact Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>
        </p>
        <h3>3. Information We Collect</h3>
        <h3>Personal Information</h3>
        <p variant="body1" className="dialog-text">
            We collect the following personal information from you:<br />
            • Name<br />
            • Email address<br />
            • Contact number<br />
            • Career-related information (e.g., career goals, current job status, education background)<br />
            • Work preferences as per your answers in our questionnaire<br />
            • Personality traits as per your answers to our questionnaire<br />
            • Resume
        </p>
        <p variant="body1" className="dialog-text">
            This information is voluntarily provided by individuals seeking career guidance and development services.
        </p>
        <h3>Usage Information</h3>
        <p variant="body1" className="dialog-text">
            We collect information on how you use our web application, including:<br />
            • IP address<br />
            • Browser type<br />
            • Pages visited<br />
            • Time spent on the site<br />
            • Referral source
        </p>
        <h3>Payment Information</h3>
        <p variant="body1" className="dialog-text">
            For users subscribing to the Premium Tier, we collect payment information necessary to process the subscription fee.
        </p>
        <h3>4. How We Use Your Information</h3>
        <h3>Personal Information</h3>
        <p variant="body1" className="dialog-text">
            We use your personal information to:<br />
            • Create and manage your account<br />
            • Provide personalized career guidance<br />
            • Communicate with you regarding your account and our services<br />
            • Improve our services and user experience
        </p>
        <p variant="body1" className="dialog-text">
            Personal information collected by SkillJourney LLP is used to create customized career journeys for our customers. This includes matching individuals with appropriate career counsellors and mentors associated with SkillJourney LLP. We do not sell or expose this information online for commercial purposes.
        </p>
        <p variant="body1" className="dialog-text">
            SkillJourney LLP may share personal information with career counsellors and mentors to facilitate the provision of our services. These third parties are contractually obligated to maintain the confidentiality and security of the information provided to them.
        </p>
        <h3>Usage Information</h3>
        <p variant="body1" className="dialog-text">
            We use usage information to:<br />
            • Analyze how our web application is used<br />
            • Improve the functionality and performance of our platform<br />
            • Ensure the security and integrity of our services
        </p>
        <h3>Payment Information</h3>
        <p variant="body1" className="dialog-text">
            We use your payment information to process transactions for the Premium Tier subscription.
        </p>
        <h3>5. Sharing Your Information</h3>
        <h3>Third-Party Service Providers</h3>
        <p variant="body1" className="dialog-text">
            We share your information with third-party service providers, including:<br />
            • OpenAI's ChatGPT Engine: We share user data anonymously to enhance our career guidance algorithm. While we strive to ensure the accuracy of the information provided by OpenAI, we do not guarantee its validity.<br />
            • Payment Processors: We share payment information with secure payment processing providers to facilitate subscription transactions.
        </p>
        <h3>Legal Requirements</h3>
        <p variant="body1" className="dialog-text">
            We may disclose your information if required to do so by law or in response to valid requests by public authorities.
        </p>
        <h3>6. Data Security</h3>
        <p variant="body1" className="dialog-text">
            We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure.
        </p>
        <h3>7. User Rights</h3>
        <h3>Access and Correction</h3>
        <p variant="body1" className="dialog-text">
            You have the right to access and update your personal information by logging into your account and editing your profile.
        </p>
        <h3>Data Retention and Deletion</h3>
        <p variant="body1" className="dialog-text">
            SkillJourney LLP retains personal information only for as long as necessary to fulfil the purposes outlined in this Privacy Policy or as required by law. We regularly review and update our data retention practices to ensure compliance with legal requirements.
        </p>
        <p variant="body1" className="dialog-text">
            You can request the deletion of your account and personal information by contacting us at <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>. We will process your request in accordance with applicable laws.
        </p>
        <h3>8. Changes to This Privacy Policy</h3>
        <p variant="body1" className="dialog-text">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>
        <h3>9. Contact Information</h3>
        <p variant="body1" className="dialog-text">
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br />
            • Email: <a href="mailto:legal@skilljourney.in">legal@skilljourney.in</a>
        </p>
        <p variant="body1" className="dialog-text">
            By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Privacy Policy.
        </p>
    </>
};
