import React, { useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Button, Grid } from '@mui/material';
import Header from '../../../header';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { Footer } from '../../footer/footer';

export const ContactUs = () => {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        tel: '',
        message: ''
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation for message length
        const messageLength = formValues.message.trim().length;
        if (messageLength < 20 || messageLength > 250) {
            setError('Message must be between 20 and 250 characters.');
            return;
        }

        // EmailJS integration
        emailjs.send(
            'service_939ebkx', // Replace with your EmailJS service ID
            'template_ckoygzn', // Replace with your EmailJS template ID
            {
                to_name: "support@skilljourney.in",
                from_name: formValues.name,
                from_email: formValues.email,
                from_contact: formValues.tel,
                message: formValues.message
            },
            'Fy_J1wFB4LmPKrSlo' // Replace with your EmailJS user ID
        ).then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
        }).catch((error) => {
            console.error('Failed to send email:', error);
        });
    };

    const handleCancel = () => {
        setFormValues({
            name: '',
            email: '',
            tel: '',
            message: ''
        });
        setError('');
    };

    return (
        <React.Fragment>
            <Container maxWidth="md">
                <Header onMainLogoClick={() => navigate('/')} />
                <Container style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Box textAlign="center" mb={2}>
                                    <Typography variant="h4" gutterBottom>
                                        Contact Us
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box mb={2}>
                                    <Typography variant="body2">
                                        <strong>Operational Address:</strong> SkillJourney LLP, 5th Floor, CC&Co, CornerStone Premises, Prabhat Road, Pune 411004
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Contact Email:</strong> <a href="mailto:support@skilljourney.in" target="_blank">support@skilljourney.in</a>
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                        <Typography variant="body2">
                            If you need to reach us, please submit the form below:
                        </Typography>
                        {error && <Box mb={2}><Typography color="error">{error}</Typography></Box>}
                        <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="tel"
                                    type="tel"
                                    value={formValues.tel}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    value={formValues.message}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>
                            <Box textAlign="center" mb={2}>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleCancel} style={{ marginLeft: '10px' }}>
                                    Clear
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Container>
                <div className="p-2">
                    <Footer />
                </div>
            </Container>
        </React.Fragment>
    );
};
