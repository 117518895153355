export const TermsAndConditionsContent = () => {
    return <>
        <h3>1. Introduction</h3>
        <p variant="body1" className="dialog-text">
            Welcome to SkillJourney NorthStar, a career guidance platform owned and operated by SkillJourney LLP. By accessing or using our web application, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please do not use our services.
        </p>
        <h3>2. Company Information</h3>
        <p variant="body1" className="dialog-text">
            • Company Name: SkillJourney LLP<br />
            • Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar, Paud Road, Pune 411038<br />
            • Current Address: 5th Floor, CC&Co, CornerStone Premises, Prabhat Road, Pune 411004<br />
            • Contact Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>
        </p>
        <h3>3. Services Provided</h3>
        <p variant="body1" className="dialog-text">
            SkillJourney NorthStar is a career guidance platform that assists users in planning and tracking their career journeys. The SkillJourney app collects the user information using their profile (uploaded resume) and a questionnaire and uses this information to generate a career journey. The generation of this career journey is done using SkillJourney’s internal algorithms and OpenAI’s generative AI capabilities.
        </p>
        <h3>4. User Responsibilities</h3>
        <h3>Account login & Data Population</h3>
        <p variant="body1" className="dialog-text">
            Users must login using one of the social login options to access the services provided by SkillJourney NorthStar. For Career Journey creation, users must provide accurate and complete information.
        </p>
        <h3>Prohibited Actions</h3>
        <p variant="body1" className="dialog-text">
            Users are prohibited from:<br />
            • Posting offensive content<br />
            • Hacking or attempting to hack the system<br />
            • Spamming or sending unsolicited messages<br />
            • Spoofing or phishing attempts
        </p>
        <h3>5. Payment Terms</h3>
        <h3>Fees</h3>
        <p variant="body1" className="dialog-text">
            • Basic Tier: Free<br />
            • Premium Tier: Rs. 999/- per annum
        </p>
        <h3>Refund Policy</h3>
        <p variant="body1" className="dialog-text">
            If SkillJourney NorthStar is unable to generate a career journey for the user, a refund will be issued. Users can request a refund by contacting <a href="mailto:support@skilljourney.in">support@skilljourney.in</a> with their username and a screen recording of the issue.
        </p>
        <h3>6. Privacy Policy</h3>
        <p variant="body1" className="dialog-text">
            For details on how user data is collected, used, and protected, please refer to our Privacy Policy.
        </p>
        <h3>7. Intellectual Property</h3>
        <p variant="body1" className="dialog-text">
            All content on SkillJourney NorthStar is owned by SkillJourney LLP. Users are allowed to use the content for personal purposes only and not for commercial purposes.
        </p>
        <h3>8. Limitation of Liability</h3>
        <p variant="body1" className="dialog-text">
            • SkillJourney uses OpenAI’s ChatGPT engine to assist in creating career journeys. The generated plan is a guidance tool and does not guarantee any job, internship, or promotion. The implementation and outcomes of the plan are the user's responsibility.<br />
            • SkillJourney is not liable for any loss of information after the plan is generated.<br />
            • SkillJourney shares user data anonymously with OpenAI’s ChatGPT engine. While SkillJourney strives to ensure the accuracy of information, it does not guarantee the validity of data provided by OpenAI.
        </p>
        <h3>9. Termination Clause</h3>
        <p variant="body1" className="dialog-text">
            SkillJourney reserves the right to terminate a user’s account if the user is found hacking the system, using it inappropriately, or indulging in prohibited actions as mentioned in these Terms.
        </p>
        <h3>10. Governing Law</h3>
        <p variant="body1" className="dialog-text">
            These Terms are governed by Indian laws and fall under the jurisdiction of Pune, India.
        </p>
        <h3>11. Changes to Terms</h3>
        <p variant="body1" className="dialog-text">
            SkillJourney may update these Terms from time to time due to:<br />
            • Legal changes in Indian company law<br />
            • New market operations<br />
            • New partnerships or vendor relationships<br />
            • Other reasonable events requiring an update<br />
            Users will be informed of changes through updates on the website with a visible indication of changes.
        </p>
        <h3>12. Dispute Resolution</h3>
        <p variant="body1" className="dialog-text">
            For any disputes, please contact <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>.
        </p>
    </>
};
