import React from 'react';
import { UserAgentApplication } from 'msal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../component/footer/footer';

const Login = () => {
    const msalConfig = {
        auth: {
            clientId: 'bfe16e69-a27c-4ad1-b86d-59722ddc4b90',
            authority: 'https://login.microsoftonline.com/8384eeec-f80c-4c3d-ac5b-111ba5938f3b',
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: 'localStorage',
        },
    };

    const msalInstance = new UserAgentApplication(msalConfig);
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await msalInstance.loginPopup({
                scopes: ['https://graph.microsoft.com/.default'],
            });

            if (response.idToken) {
                // Retrieve access token from the response
                const accessTokenResponse = await msalInstance.acquireTokenSilent({
                    scopes: ['https://graph.microsoft.com/.default'],
                });

                if (accessTokenResponse.accessToken) {
                    // Store the access token in local storage
                    localStorage.setItem('accessToken', accessTokenResponse.accessToken);
                    navigate('/admin/UploadForm');
                } else {
                    console.error('Access token not found in the response');
                }
            } else {
                console.error('ID token not found in the response');
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <Container>
            <Content>
                <Title>Login to OneDrive</Title>
                <LoginButton onClick={handleLogin}>Login</LoginButton>
            </Content>
            <div className=" p-2">
                <Footer />
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items to the top */
    height: 100vh;
    background-color: #f0f0f0;
    padding: 20px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px; /* Adds space from the top */
`;

const Title = styled.h3`
    color: #333;
    margin-bottom: 20px;
`;

const LoginButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

export default Login;
