import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, InputLabel, TextField, FormControl, Select, MenuItem } from "@mui/material";

export const TextBox = (props) => {
    const { question, formData, answer, step, index } = props;
    const [countryCode, setCountryCode] = useState(formData.countryCode || '');
    const [countryCodes, setCountryCodes] = useState([]);
    const [emailError, setEmailError] = useState(false); // State to track email validation

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const codes = response.data.map(country => ({
                    name: country.name.common,
                    code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
                })).filter(country => country.code);
                setCountryCodes(codes);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
    }, []);

    const getMaxLength = (name) => {
        const fieldsWithMax25 = ["name", "coreValues", "enjoyedActivities"];
        return fieldsWithMax25.includes(name) ? 25 : 250; // Default max length is 250
    };

    const handleCountryCodeChange = (event) => {
        const { value } = event.target;
        setCountryCode(value);
        props.handleRadioButtonChange({ target: { name: question.name, value: value } });
    };

    const validateEmail = (email) => {
        // Simple email validation
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        props.handleRadioButtonChange(e); // Update form data

        // Validate email format
        setEmailError(!validateEmail(value));
    };

    return (
        <Grid item xs={12}>
            <div className="input-wrapper">
                <InputLabel
                    htmlFor={question.name}
                    className="mb-4 text-red text-lg"
                    style={{ fontSize: '20px', fontFamily: 'Arial, sans-serif', color: 'black', whiteSpace: 'normal' }}
                >
                    {`${step}.${index + 1} ${question.label} ${question.required ? '*' : ''}`}
                </InputLabel>
                <div style={{ marginBottom: '20px', fontSize: '14px' }}>{question.example}</div>
                {question.name === "contactNumber" && !(formData[question.name]) && (
                    <FormControl fullWidth variant="filled" className="mb-5">
                        <InputLabel id="country-code-label">Country Code</InputLabel>
                        <Select
                            labelId="country-code-label"
                            id="country-code-select"
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            required={question.required}
                        >
                            {countryCodes.map(country => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.code} ({country.name})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <TextField
                    fullWidth
                    id={question.name}
                    name={question.name}
                    required={question.required}
                    value={formData[question.name] === undefined ? (answer || '') : formData[question.name]}
                    onChange={question.name === 'email' ? handleEmailChange : props.handleRadioButtonChange}
                    className="mb-5"
                    variant="filled"
                    autoComplete="off"
                    inputProps={{ maxLength: getMaxLength(question.name) }}
                    error={question.name === 'email' && emailError}
                    helperText={question.name === 'email' && emailError ? 'Please enter a valid email address' : ''}
                />
            </div>
        </Grid>
    );
};
