import { BackendApi } from "./config/ApiFormatter";

export const UploadFormData = async () => {

  const uploadUrl = BackendApi() + "uploadUserQuestionnaire";
  const idToken = sessionStorage.getItem('AccessToken'); // Fetch the ID token from session storage
  const data = {
    username: sessionStorage.getItem('Email'),
    questionnaire: []
  };
  const formDataObj = new FormData();
  formDataObj.append('data', JSON.stringify(data));
  const response = await fetch(uploadUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${idToken}`
    },
    body: formDataObj
  });
  return response;
};
