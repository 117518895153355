import { useState } from 'react';
import { GoogleLogin } from "@react-oauth/google";

const GoogleLoginButton = ({ onSuccess, onError }) => {
    // State to store the ID token
    const [idToken, setIdToken] = useState(null);

    const handleSuccess = (response) => {
        if (response?.credential) {
            // Extract ID token from the response
            const idToken = response.credential; // Assuming the token is stored in the credential property

            setIdToken(idToken); // Store the ID token in state
            sessionStorage.setItem('AccessToken', idToken); // Store token in sessionStorage
            // Call the onSuccess callback with the ID token
            if (onSuccess) {
                onSuccess(idToken);
            }
        } else {
            console.error('ID token not found in response');
            if (onError) {
                onError('ID token not found in response');
            }
        }
    };

    const handleError = (error) => {
        console.error('Login Failed:', error);
        if (onError) {
            onError(error);
        }
    };

    return (
        <>
            <GoogleLogin
                clientId="YOUR_GOOGLE_CLIENT_ID"
                onSuccess={handleSuccess}
                onFailure={handleError}
                cookiePolicy={'single_host_origin'}
                buttonText="Login with Google"
            />
        </>
    );
};

export default GoogleLoginButton;
