import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { KosmosApi } from "../api/config/ApiFormatter";
import { Footer } from '../component/footer/footer';

const apiUrl = KosmosApi() + "uploadOneDrive";

const UploadForm = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false); // State to track if user is logged in

  useEffect(() => {
    // Check if the user is logged in
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setLoggedIn(true);
    } else {
      // Redirect to the login page if not logged in
      window.location.href = '/admin/login'; // Change to your login page URL
    }
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const handleUpload = async () => {
    try {
      setLoading(true); // Set loading state to true
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('Access token not found');
        setLoading(false);
        return;
      }

      const response = await uploadToServer(accessToken);

      if (response.ok) {
        setUploadSuccess(true);
        localStorage.clear();
        setTimeout(() => {
          // Open OneDrive in a new tab after successful upload
          window.open('https://onedrive.com', '_blank');
        }, 3000);
      } else {
        console.error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    localStorage.clear();
    window.location.href = '/admin/login';
  };

  const handleHome = () => {
    localStorage.clear();
    window.location.href = '/'; // Change to your home page URL
  };

  const uploadToServer = async (accessToken) => {
    return await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ /* Add any additional data you want to send */ }),
    });
  };

  if (!loggedIn) return null;

  return (
    <Container>
      <Title>Upload Form</Title>
      <ButtonGroup>
        <BackButton onClick={handleBack}>Back</BackButton>
        <HomeButton onClick={handleHome}>Home</HomeButton>
      </ButtonGroup>
      {loading && <LoadingMessage>Loading...</LoadingMessage>}
      {uploadSuccess && !loading && (
        <SuccessContainer>
          <SuccessMessage>Upload successful! Redirecting to OneDrive...</SuccessMessage>
        </SuccessContainer>
      )}
      {!loading && !uploadSuccess && (
        <UploadButton onClick={handleUpload}>Upload Journeys to OneDrive</UploadButton>
      )}
      <div className=" p-2">
        <Footer />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Title = styled.h3`
  color: #333;
  margin-bottom: 20px;
`;

const LoadingMessage = styled.p`
  color: #007bff;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessMessage = styled.p`
  color: #28a745;
  animation: ${keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `} 1s ease-in-out;
`;

const UploadButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

const HomeButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 10%;
`;

export default UploadForm;
