
import { BackendApi } from "./config/ApiFormatter";
export const CheckEmail = async () => {

    const apiUrl =  BackendApi()+"checkEmail"

   try{
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken:sessionStorage.getItem('AccessToken')})
        });


        const result = await response.json();
        sessionStorage.setItem('Email', result.email);
   
        return result; 
    }catch(error){
  
    }
};
