import axios from 'axios';
import { BackendApi } from './config/ApiFormatter';

export const InitiatePayment = async () => {
    const apiUrl = BackendApi() + 'initiatePayment';
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error generating order ID:', error);
    }
};


export const verifyAndSavePaymentCredential = async (response, formattedTimestamp) => {
    const apiUrl = BackendApi() + 'verifyAndSavePaymentCredential';
    const payload = {
        order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature : response.razorpay_signature,
        Payment_success_timestamp: formattedTimestamp,
    };

    try {
        const response = await axios.put(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
            },
        });
        return response;
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
    }
};

export const dropPaymentDetails = async () => {
    const apiUrl = BackendApi() + 'dropPaymentDetails';
    try {
        const response = await axios.delete(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting Payment details:', error);
    }
};