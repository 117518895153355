import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import mainLogo from '../../assets/images/logo.png';
import { InitiatePayment, verifyAndSavePaymentCredential } from '../../api/payments';
import { useNavigate } from 'react-router-dom';
import { SnackbarComponent } from '../containers/Snackbar/snackbarComponent';

export const RazorpayButton = () => {
  const navigate = useNavigate();
  const [backendMessage, setBackendMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const paymentResponseHandler = async (response) => {
    console.log('razorpay payment success response:', response);
    const now = new Date();
    const formattedTimestamp = now.toLocaleString();
    const re = await verifyAndSavePaymentCredential(response, formattedTimestamp);
    if (re.status == 200) {
      navigate('/premiumJourney');
    } else if (re.status == 406 || re.status == 500) {
      setBackendMessage(re.data.message);
      setIsSnackbarOpen(true);
    }
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    const response = await InitiatePayment();
    if (response.status === "created") {
      // Define Razorpay options
      const options = {
        "key": response.razorpay_key, // Enter the Key ID generated from the Dashboard
        "amount": response.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": response.currency,
        "name": "North Star", //your business name
        "description": "Test Transaction",
        "image": mainLogo,
        "order_id": response.order_id, //This is the order ID obtained in the response
        "handler": paymentResponseHandler,
        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          "name": response.name, //your customer's name
          "email": response.email,
          "contact": response.contact //Provide the customer's phone number for better conversion rates 
        },
        "notes": {
          "address": "Skilljourney office"
        },
        "theme": {
          "color": "#3399cc"
        }
      };
      console.log(options);
      // Initialize Razorpay payment
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else if (response.status === "paid") {
      setIsSnackbarOpen(true);
      setBackendMessage(response.status);
      setTimeout(() => {
        navigate('/premiumJourney');
      }, 1000);
    }
  };

  useEffect(() => {
    // Dynamically load the Razorpay script
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => { console.log('Razorpay script loaded successfully') };
    script.onerror = () => { console.error('Failed to load Razorpay script') };
  }, []);

  return (
    <div>
      <Button onClick={paymentHandler}
        variant="contained"
        color="primary"
        className="mt-4" >Premium Journey</Button>
      <SnackbarComponent isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} backendMessage={backendMessage} />
    </div>
  );
};
