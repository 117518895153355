export const CancellationAndRefundContent = () => {
    return (
        <>
            <div className="cancellation-refund-content">
                <h3>1. Introduction</h3>
                <p className="dialog-text">
                    This Cancellation and Refund Policy outlines the terms and conditions under which refunds will be issued for services provided by SkillJourney NorthStar, owned and operated by SkillJourney LLP. By using our services, you agree to the terms of this policy.
                </p>

                <h3>2. Company Information</h3>
                <p className="dialog-text">
                    <ul>
                        <li>Company Name: SkillJourney LLP</li>
                        <li>Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar, Paud Road, Pune 411038</li>
                        <li>Current Address: SkillJourney LLP, 5th Floor, CC&Co, CornerStone Premises, Prabhat Road, Pune 411004</li>
                        <li>Contact Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a></li>    
                    </ul>
                </p>

                <h3>3. Service Tiers</h3>
                <h3>Basic Tier</h3>
                <p className="dialog-text">The Basic Tier is free of charge.</p>

                <h3>Premium Tier</h3>
                <p className="dialog-text">The Premium Tier is available for Rs. 999/- per annum.</p>

                <h3>4. Cancellation Policy</h3>
                <h3>Basic Tier</h3>
                <p className="dialog-text">Users of the Basic Tier can stop using the service at any time without any formal cancellation process.</p>

                <h3>Premium Tier</h3>
                <p className="dialog-text">Users of the Premium Tier can cancel their subscription at any time by contacting our support team at <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>.</p>

                <h3>5. Refund Policy</h3>
                <p className="dialog-text">Refunds will be issued under the following conditions:</p>

                <h3>Eligibility</h3>
                <p className="dialog-text">
                    <ul>
                        <li>A refund will be issued if SkillJourney NorthStar is unable to generate your career journey plan.</li>
                        <li>Refund requests must be submitted within 30 days of the service failure.</li>
                    </ul>
                </p>

                <h3>Process</h3>
                <p className="dialog-text">
                    To request a refund, please follow these steps:
                    <ol>
                        <li>Contact our support team at <a href="mailto:support@skilljourney.in">support@skilljourney.in</a> with the following information:
                            <ul>
                                <li>Your username</li>
                                <li>A screen recording of the issue demonstrating the failure to generate your career journey plan.</li>
                            </ul>
                        </li>
                        <li>Our support team will review your request and respond within 5-7 business days.</li>
                    </ol>
                </p>

                <h3>Non-Refundable Situations</h3>
                <p className="dialog-text">
                    <ul>
                        <li>Refunds will not be issued for users who cancel their Premium Tier subscription after successfully generating a career journey plan.</li>
                        <li>Refunds will not be issued if the user fails to provide the necessary information and documentation as requested by our support team.</li>
                        <li>Refunds will not be issued for any dissatisfaction with the outcomes or results of the career journey plan, as implementation and outcomes are the user's responsibility.</li>
                    </ul>
                </p>

                <h3>6. Contact Information</h3>
                <p className="dialog-text">
                    For any questions or concerns regarding our Cancellation and Refund Policy, please contact us at:
                    <br/>
                    Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>
                </p>

                <hr className="bold-hr"/>
                <p className="dialog-text">
                    By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Cancellation and Refund Policy.
                </p>
            </div>
            <style jsx>{`
                .cancellation-refund-content {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }

                .cancellation-refund-content h3 {
                    margin-top: 20px;
                }

                .cancellation-refund-content h3 {
                    margin-top: 10px;
                }

                .cancellation-refund-content .dialog-text {
                    margin-bottom: 10px;
                }

                .cancellation-refund-content ul {
                    list-style-type: disc;
                    margin-left: 20px;
                }

                .cancellation-refund-content ol {
                    list-style-type: decimal;
                    margin-left: 20px;
                }

                .bold-hr {
                    border: none;
                    height: 3px;
                    background-color: #000;
                }
            `}</style>
        </>
    );
};
