export const ShippingAndDeliveryContent = () => {
    return (
        <>
            <div className="shipping-delivery-content">
                <h3>1. Introduction</h3>
                <p className="dialog-text">
                This Shipping and Delivery Policy outlines the terms and conditions under which services are delivered to users of SkillJourney NorthStar, a career guidance platform owned and operated by SkillJourney LLP. By using our services, you agree to the terms of this policy.
                </p>

                <h3>2. Company Information</h3>
                <p className="dialog-text">
                    • Company Name: SkillJourney LLP<br />
                    • Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar, Paud Road, Pune 411038<br />
                    • Current Address: SkillJourney LLP, 5th Floor, CC&Co, CornerStone Premises, Prabhat Road, Pune 411004<br />
                    • Contact Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>
                </p>

                <h3>3. Service Delivery</h3>
                <p className="dialog-text">SkillJourney NorthStar is a digital career guidance platform. As such, all services are provided online and there are no physical goods shipped to users. Below are the key aspects of our service delivery:</p>

                <h3>Account Creation and Activation</h3>
                <p className="dialog-text">
                    • Account Creation: Users must create an account to access the services of SkillJourney NorthStar. Upon successful registration, users will receive a confirmation email with account details.<br />
                    • Account Activation: Once the account is created, users can immediately access the Basic Tier services for free. For Premium Tier services, users must complete the payment process.
                </p>

                <h3>Premium Tier Subscription</h3>
                <p className="dialog-text">
                    • Subscription Fee: The Premium Tier is available for Rs. 999/- per annum.<br />
                    • Access: Upon successful payment, users will receive immediate access to Premium Tier features. A confirmation email with subscription details will be sent to the registered email address.
                </p>

                <h3>Delivery of Digital Services</h3>
                <p className="dialog-text">
                    • Career Journey Plan: Users can generate a personalized career journey plan using the platform. The plan is delivered digitally and can be accessed through the user's account.<br />
                    • Guidance and Tracking: The platform provides ongoing guidance and allows users to track their progress. All services are delivered digitally through the web application.
                </p>

                <h3>4. User Responsibilities</h3>
                <p className="dialog-text">
                    <strong>Accurate Information</strong><br />
                    • Users must provide accurate and complete information during account creation and throughout their use of the platform to ensure the best possible service delivery.<br />
                    <strong>Prohibited Actions</strong><br />
                    • Users are prohibited from posting offensive content, hacking, spamming, spoofing, or phishing. Any such actions may result in termination of the user account.
                </p>

                <h3>5. Refund and Cancellation</h3>
                <p className="dialog-text">• Please refer to our Cancellation and Refund Policy for details on refunds and cancellation of the Premium Tier subscription.</p>

                <h3>6. Contact Information</h3>
                <p className="dialog-text">
                    For any questions or concerns regarding our Shipping and Delivery Policy, please contact us at:<br />
                    • Email: <a href="mailto:support@skilljourney.in">support@skilljourney.in</a>
                </p>

                <hr className="bold-hr"/>
                <p className="dialog-text">
                    By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Shipping and Delivery Policy.
                </p>
            </div>
            <style jsx>{`
                .shipping-delivery-content {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }

                .shipping-delivery-content h3 {
                    margin-top: 20px;
                }

                .shipping-delivery-content .dialog-text {
                    margin-bottom: 10px;
                }

                .shipping-delivery-content ul {
                    list-style-type: disc;
                    margin-left: 20px;
                }

                .shipping-delivery-content ol {
                    list-style-type: decimal;
                    margin-left: 20px;
                }

                .bold-hr {
                    border: none;
                    height: 3px;
                    background-color: #000;
                }
            `}</style>
        </>
    );
};
