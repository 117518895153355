import React, { useLayoutEffect, useState } from "react";
import { DynamicContainer } from "./DynamicContainer";
import { GetUserQuestionnaire } from "../../api/GetUserQuestionnaire";

export const OuterContainer = (props) => {
    const [answeredQuestion, setAnsweredQuestion] = useState({});
    useLayoutEffect(() => { GetUserQuestionnaire({ setAnsweredQuestion }) }, [props.currentStep]);
    
    return (
        <div className="input-container">
            <DynamicContainer
                step={props.case}
                questionArray={props.questions[props.case - 1]}
                answeredQuestionArray={answeredQuestion[props.case - 1]}
                formData={props.formData}
                setFormData={props.setFormData}
                setDraftData={props.setDraftData}
            />
        </div>
    );
};
