import { BackendApi } from "./config/ApiFormatter";
export const FetchhtmlJourney = async ({ setHtmlJourney }) => {
  const apiUrl =
    BackendApi() + "getHTMLCareerJourney";

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("AccessToken")}`,
      },
    });

    const result = await response.json();

    setHtmlJourney(result.data);
  } catch (error) {
  
  }
};

export const FetchUserInfo = async ({ setUserName }) => {
  const apiUrl =
    BackendApi() + "readUserQuestionnaire";


  try {
    const idToken = sessionStorage.getItem("AccessToken"); // Fetch the ID token from session storage

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${idToken}`, // Include the id_token in the Authorization header
      },
    });
    const questionWithAnswers = await response.json();

    if (!response.ok) {
      throw new Error("Error fetching user questionnaire");
    }
    const questionWithAnswersArray = questionWithAnswers.data[0];
    const FullNameArray = questionWithAnswersArray.questions[0];
    setUserName(FullNameArray.reply);
  } catch (error) {
    console.error("Error fetching user questionnaire:", error);
    throw error; // Rethrow error to handle it in the component
  }
};
