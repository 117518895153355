import React, { useState } from 'react';
import { Typography, Box, Tooltip, IconButton, Avatar, Menu, MenuItem } from '@mui/material';

const Header = ({ userName, isSignedIn, onSignOut, onMainLogoClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = (event) => {
    event.preventDefault(); // Prevent default behavior
    if (onSignOut) onSignOut();
    handleClose(); // Close the menu after sign out
  };

  const handleMainLogoClick = () => { onMainLogoClick()};

  return (
    <div style={{ marginTop: '15px' }}  className="flex items-center justify-between p-0 px-2.5 bg-gradient-to-r from-[#D4A5FD] to-[#B1D8FF] rounded-[12px] shadow-md h-[125px]">
      <div className="flex items-center justify-center">
        <Typography component="a" className="block">
          <img src="/logo.png" alt="SkillJourney Logo" className="w-[300px] h-auto"  onClick={handleMainLogoClick}/>
        </Typography>
      </div>
      <div className="flex-1 flex justify-end">
        {isSignedIn && (
          <Box className="flex items-center text-center">
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                className="ml-2"
                aria-controls={anchorEl ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
              >
                <Avatar className="w-[32px] h-[32px]">{userName.charAt(0).toUpperCase()}</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </div>
  );
};

export default Header;
