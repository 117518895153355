import { BackendApi } from "./config/ApiFormatter";

export const SectionWiseInsertion = async (currentStep, draftData, questions) => {
    const uploadUrl = BackendApi() + "updateUserQuestionnaire";
    const idToken = sessionStorage.getItem('AccessToken'); // Fetch the ID token from session storage
  
    const data = {
      username: sessionStorage.getItem('Email'),
      questionnaire: constructQuestionnaireSection(currentStep, draftData, questions)
    };
    const formDataObj = new FormData();
    formDataObj.append('data', JSON.stringify(data));
  
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${idToken}` 
      },
      body: formDataObj
    });
    return response;
  };

  const constructQuestionnaireSection = (currentStep, draftData, questions) => {
    const section = questions[currentStep-1];
    return [{
        sectionId: currentStep,
        title: section.title,
        questions: section.fields.map((question, idx) => {
          const reply = draftData[question.name];
          const questionLabel = question.label;
          const id = `${currentStep}.${idx + 1}`;
          return {
            id: id,
            question: questionLabel,
            reply: reply,
          };
        })
      }];
  }; 