import React from 'react';
import { Grid } from '@mui/material';
import RadioButtonGroup from '../buttons/RadioButtonGroup';

const RadioBox = (props) => {
  const { step, index, question, formData, handleRadioButtonChange, answer } = props;

  return (
    <Grid item xs={12}>
      <div className="input-wrapper">
        <span style={{ fontSize: '20px', fontFamily: 'Arial, sans-serif', color: 'Black' }}>
          {step}.{index + 1} {question.label} {question.required ? '*' : ''}
        </span>
        <div style={{ marginTop: '20px' }}>
          <RadioButtonGroup
            name={question.name}
            value={formData[question.name] || answer || ''} // Set value from formData or answered value
            onChange={handleRadioButtonChange}
            options={question.options.map((option, idx) => ({
              value: option.value,
              label: option.label,
              key: idx
            }))}
            required={question.required}
          />
        </div>
      </div>
    </Grid>
  );
};

export default RadioBox;
