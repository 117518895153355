import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Tooltip } from "@mui/material";
import { PrivacyPolicyDialog } from '../page/privacypolicy/PrivacyPolicyDialog';
import { CheckEmail } from '../../api/CheckEmail'; // Adjust the path as per your project structure
import { useNavigate } from "react-router-dom";
import { UploadFormData } from '../../api/UploadFormData';
import { SectionWiseInsertion } from '../../api/SectionWiseInsertion';

export const RenderButtons = (props) => {
    const navigate = useNavigate();
    const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(JSON.parse(sessionStorage.getItem('isChecked')) || false);
    const [disableAgreeStep, setDisableAgreeStep] = useState(JSON.parse(sessionStorage.getItem('disableAgreeStep')) || false);
    const [emailCheckResult, setEmailCheckResult] = useState(JSON.parse(sessionStorage.getItem('emailCheckResult')) || { emailIdPresent: false, editAllowed: true, journeyExist: false });

    useEffect(() => {
        sessionStorage.setItem('emailCheckResult', JSON.stringify(emailCheckResult));
        sessionStorage.setItem('isChecked', JSON.stringify(isChecked));
        sessionStorage.setItem('disableAgreeStep', JSON.stringify(disableAgreeStep));
    }, [emailCheckResult, isChecked, disableAgreeStep]);

    const commonButtonStyle = {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: 'purple',
        color: 'white',
        height: '50px',
        width: '140px',
    };


    const handleNextStep = async () => {
        if (props.currentStep === 0) {
            if (!isChecked) {
                alert('Please agree to the Privacy Policy before proceeding.');
            } else {
                const result = await CheckEmail(props.formData.email);
                var journeyExist = result.journeyExist;
                var emailIdPresent = result.emailIdPresent;
                var editAllowed = result.editAllowed;
                setEmailCheckResult({ emailIdPresent, editAllowed, journeyExist });
                if (!emailCheckResult.emailIdPresent) {
                    setDisableAgreeStep(true);
                    if (!isStepValid()) {
                        alert('Please fill all required fields before proceeding to the next step.');
                    }
                } else {
                    props.setIsEditing(true);
                    setDisableAgreeStep(true);
                }
            }
        } else {
            const response = await SectionWiseInsertion(props.currentStep, props.draftData, props.questions)
            if (emailCheckResult.emailIdPresent && response.ok && isStepValid()) {
                props.setDraftData({});
                props.setCurrentStep(props.currentStep + 1);
            } else {
                if (isStepValid() && response.ok) {
                    props.setDraftData({});
                    props.setCurrentStep(props.currentStep + 1);
                } else {
                    alert('Please fill all required fields before proceeding to the next step.');
                }
            }
        }
    };

    const handleEdit = () => {
        props.setCurrentStep(props.currentStep + 1);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const isStepValid = () => {
        const { formData, draftData } = props
        const questionSection = props.questions[props.currentStep - 1];
        const requiredQuestionsArray = questionSection.fields.filter(question => question.required === true);
        return requiredQuestionsArray.every((requiredQuestion, index) => {
            const value = formData[requiredQuestion.name.toString()] || draftData[requiredQuestion.name.toString()];
            if (Array.isArray(value)) {
                return value.every(item => typeof item === 'string' && item.trim() !== '');
            } else {
                return typeof value === 'string' && value.trim() !== '';
            }
        });
    };

    const handleGoAhead = async () => {
        try {
            const response = await UploadFormData();
            if (response.ok) {
                setDisableAgreeStep(true);
                props.setCurrentStep(props.currentStep + 1);
            }
        } catch (error) {
            console.error("Error uploading form data:", error);
        }
    };

    return (<>
        {props.currentStep === 0
            ? <Box display="flex" alignItems="center" mt={2} pb={44}>
                {!disableAgreeStep
                    ? <Box display="flex" alignItems="center" gap={2}>
                        <div>
                            <Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" sx={{ pl: 0 }} />
                            <span>I agree to the{" "}
                                <a href="#" onClick={() => setPrivacyPolicyDialogOpen(true)}
                                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} >
                                    Privacy Policy
                                </a>
                            </span>
                        </div>
                        <Button variant="contained" onClick={handleNextStep} style={commonButtonStyle}> Next </Button>
                    </Box>
                    : <div display="flex" alignItems="center" gap={2}>
                        {emailCheckResult.emailIdPresent
                            ? <>{emailCheckResult.editAllowed
                                ? <Button variant="contained" onClick={handleEdit} style={commonButtonStyle} >
                                    Edit Questionnaire</Button>
                                : <Tooltip title='You have exceeded the editing limit. Please contact support@skilljourney.in for further edits.'>
                                    <span><Button variant="contained" disabled style={{
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        height: '50px',
                                        marginLeft: '1rem',
                                        width: '140px'
                                    }} >
                                        Edit Questionnaire</Button></span>
                                </Tooltip>
                            }
                            </>
                            : <Button variant="contained" onClick={handleGoAhead} style={commonButtonStyle} >
                                Go To Questionnaire</Button>}

                        {emailCheckResult.journeyExist ?
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/myJourney')}
                                style={{ marginLeft: '1rem', ...commonButtonStyle }}
                            >
                                View My Journey
                            </Button>
                            : <Tooltip title='You have already submitted your answers. You will receive your career journey on your registered email ID. If you have any questions, please contact support@skilljourney.in.'>
                                <span><Button variant="contained" disabled style={{
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    height: '50px',
                                    marginLeft: '1rem',
                                    width: '140px'
                                }} >
                                    View My Journey</Button></span>
                            </Tooltip>}
                    </div>}
            </Box >
            : <Box display="flex" justifyContent="space-between" mt={2} >
                <Button variant="contained" onClick={() => props.setCurrentStep(props.currentStep - 1)} style={commonButtonStyle} >
                    Previous </Button>
                {props.currentStep === (props.questions).length
                    ? <Button variant="contained" color="primary" type="submit" style={commonButtonStyle} >
                        Submit </Button>
                    : <Button variant="contained" onClick={handleNextStep} style={commonButtonStyle} >
                        Next</Button>
                }
            </Box>}
        <PrivacyPolicyDialog open={privacyPolicyDialogOpen} onClose={() => setPrivacyPolicyDialogOpen(false)} />
    </>)
};
