import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';

const CheckBox = (props) => {
  const { step, index, question, formData, handleCheckboxChange, setRequired, answer } = props;

  useEffect(() => {
    if (question.required) {
      setRequired(true);
    }
  }, [question.required, setRequired]);

  const formDataWorkEnv = formData.workEnv || (answer ? [...answer] : []);
 
  
  return (
    <Grid item xs={12}>
      <div className="input-wrapper">
        <span style={{ fontSize: '20px', fontFamily: 'Arial, sans-serif', color: 'Black' }}>
          {step}.{index + 1} {question.label} {question.required ? '*' : ''}
        </span>
        <div style={{ marginTop: '20px' }}>
          <FormGroup>
            {question.options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={formDataWorkEnv.includes(option.value)}
                    onChange={handleCheckboxChange}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    </Grid>
  );
};

export default CheckBox;
