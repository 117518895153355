export const messages = {
  "#4CAF50": ["submitted", "successfully", "paid"],
  red: [
    "not inserted",
    "not deleted",
    "ValueError",
    "Failed",
    "not found",
    "exceeded the editing limit", "Wrong", "problem"
  ],
  "#FFD700": ["No changes detected"],
};

export const messagesColor = (message) => {
  for (const [key, values] of Object.entries(messages)) {
    if (values.some((v) => message.includes(v))) {
      return key;
    } else {
      return "#FFD700"
    }
  }
};
