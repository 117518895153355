
import { BackendApi } from "./config/ApiFormatter";
export const GetUserQuestionnaire = async ({ setAnsweredQuestion }) => {
    const apiUrl = BackendApi()+ "readUserQuestionnaire";

    try {
        const idToken = sessionStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${idToken}` // Include the id_token in the Authorization header
            }
        });
        const questionWithAnswers = await response.json();

        if (!response.ok) {
            throw new Error('Error fetching user questionnaire');
        }

        setAnsweredQuestion(questionWithAnswers.data);
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};
