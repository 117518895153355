import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../../App.css'; // Import CSS file
import { PrivacyContent } from './privacyContent';

function PrivacyPolicyDialog({ open, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-container" // Apply class name
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title">
        <div className="dialog-text-title">Privacy Policy</div>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <p variant="body1" className="dialog-text">
          <strong style={{ fontSize: '1.2em' }}>Privacy Policy</strong>
        </p>
        <PrivacyContent />
      </DialogContent>
    </Dialog>
  );
}

export { PrivacyPolicyDialog };
