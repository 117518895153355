import React from 'react';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer className="flex items-center justify-around p-0 px-2.5 bg-gradient-to-r from-[#D4A5FD] to-[#B1D8FF] rounded-[12px] shadow-md h-[125px]">
            <div className="container grid grid-cols-2 gap-20 px-4 py-2">
                <div className="col-span-3 flex justify-center">
                    <img
                        onClick={() => navigate('/')}
                        src={logo}
                        alt="Logo"
                        className="h-40 w-auto cursor-pointer"
                    />
                </div>
                <div className="col-span-12 py-4">
                    <nav className="flex justify-evenly gap-4">
                        <div>
                            <FooterLink href="/aboutUs">About Us</FooterLink>
                            <FooterLink href="/aboutUs/shippingAndDelivery">Shipping & Delivery</FooterLink>

                        </div>
                        <div>
                            <FooterLink href="/aboutUs/privacyPolicy">Privacy Policy</FooterLink>
                            <FooterLink href="/aboutUs/cancellationAndRefund">Cancellation & Refund</FooterLink>
                        </div>
                        <div>
                            <FooterLink href="/contactUs">Contact Us</FooterLink>
                            <FooterLink href="/aboutUs/termsAndConditions">Terms & Conditions</FooterLink>
                        </div>
                    </nav>
                    <div className="flex items-center justify-center">
                        <p className="text-sm">&copy; 2024 SkillJourney. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

const FooterLink = ({ href, children }) => {
    const navigate = useNavigate();
    return (
        <a
            onClick={() => navigate(href)}
            className="block text-black hover:text-blue-500 transition-colors duration-300 cursor-pointer text-sm pb-2"
        >
            {children}
        </a>
    );
};
