import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Login from "./admin/login";
import UploadForm from "./admin/UploadForm";
import { DropPaymentDetails } from "./component/page/dropPaymentDetails/dropPaymentDetails";
import { AboutUs } from "./component/page/aboutUs/aboutUs";
import { PrivacyPolicy } from "./component/page/privacypolicy/privacyPolicy";
import { MyJourney } from "./component/page/myJourney/myJourney";
import { PremiumJourney } from "./component/page/premiumJourney/premiumJourney";
import { TermsAndConditions } from "./component/page/termsAndConditions/termsAndConditions";
import { CancellationAndRefund } from "./component/page/cancellationAndRefund/cancellationAndRefund";
import { ShippingAndDelivery } from "./component/page/shippingAndDelivery/shippingAndDelivery";
import { ContactUs } from "./component/page/contactUs/contactUs";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/UploadForm" element={<UploadForm />} />
          <Route path="myJourney" element={<MyJourney />} />
          <Route path="premiumJourney" element={<PremiumJourney />} />
          <Route path="premiumJourney/dropPaymentDetails" element={<DropPaymentDetails />} />
          <Route path="aboutUs" element={<AboutUs/>} />
          <Route path="aboutUs/privacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="aboutUs/termsAndConditions" element={<TermsAndConditions/>} />
          <Route path="aboutUs/cancellationAndRefund" element={<CancellationAndRefund/>} />
          <Route path="aboutUs/shippingAndDelivery" element={<ShippingAndDelivery/>} />
          <Route path="contactUs" element={<ContactUs/>} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);