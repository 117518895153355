import { useEffect } from "react";
import { dropPaymentDetails } from "../../../api/payments";

// testing purpose only

export const DropPaymentDetails = () => {
    const HandleDropPayment = async () => {
        const response = await dropPaymentDetails();
        alert(response.message);
    };
    useEffect(() => { HandleDropPayment() }, []);
};