import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { UpdateFormData } from "./api/UpdateFormData";
import { RenderButtons } from "./component/buttons/RenderButtons";
import { RenderStep } from "./component/RenderStep";
import GoogleLoginButton from "./component/google/GoogleLoginButton";
import { GetQuestionSet } from "./api/GetQuestionSet";
import { PrivacyPolicyDialog } from "./component/page/privacypolicy/PrivacyPolicyDialog";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.css"; // Import the CSS file
import { FetchUserInfo, FetchhtmlJourney } from "./api/FetchhtmlJourney";
import Header from "./header";
import { CheckEmail } from "./api/CheckEmail"; // Import CheckEmail functi
import { SnackbarComponent } from "./component/containers/Snackbar/snackbarComponent";
import { Footer } from "./component/footer/footer";

function ReactForm() {
  const [isJourneyCreated, setIsJourneyCreated] = useState(false);
  const [journeyInterval, setJourneyInterval] = useState(null);

  const [setHtmlJourney] = useState("");
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState(
    sessionStorage.getItem("userName") || ""
  );
  const [currentStep, setCurrentStep] = useState(
    Number(sessionStorage.getItem("currentStep")) || 0
  );
  const [questions, setQuestions] = useState(
    JSON.parse(sessionStorage.getItem("questions")) || {}
  );
  const [isEditing, setIsEditing] = useState(
    JSON.parse(sessionStorage.getItem("isEditing")) || false
  );
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(
    JSON.parse(sessionStorage.getItem("isSignedIn")) || false
  );
  const [backendMessage, setBackendMessage] = useState("");
  const [formData, setFormData] = useState(
    JSON.parse(sessionStorage.getItem("formData")) || {}
  );
  const [draftData, setDraftData] = useState(
    JSON.parse(sessionStorage.getItem("draftData")) || {}
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(
    JSON.parse(sessionStorage.getItem("isFormSubmitted")) || false
  );
  const [isJourneyInProgress, setIsJourneyInProgress] = useState(
    JSON.parse(sessionStorage.getItem("isJourneyInProgress")) || false
  );
  const [showViewJourneyButton, setShowViewJourneyButton] = useState(
    JSON.parse(sessionStorage.getItem("showViewJourneyButton")) || false
  );
  const [anchorEl, setAnchorEl] = useState(null); // State for account menu anchor
  const { redirect } = location.state || "";
  const [emailCheckResult, setEmailCheckResult] = useState({
    journeyExist: false,
    emailIdPresent: false,
    editAllowed: false,
  });

  useEffect(() => {
    sessionStorage.setItem("userName", userName);
    sessionStorage.setItem("currentStep", currentStep);
    sessionStorage.setItem("questions", JSON.stringify(questions));
    sessionStorage.setItem("isEditing", isEditing);
    sessionStorage.setItem("isSignedIn", isSignedIn);
    sessionStorage.setItem("formData", JSON.stringify(formData));
    sessionStorage.setItem("draftData", JSON.stringify(draftData));
    sessionStorage.setItem("isFormSubmitted", isFormSubmitted);
    sessionStorage.setItem("isJourneyInProgress", isJourneyInProgress);
    sessionStorage.setItem("showViewJourneyButton", showViewJourneyButton);
  }, [
    userName,
    currentStep,
    questions,
    isEditing,
    isSignedIn,
    formData,
    draftData,
    isFormSubmitted,
    isJourneyInProgress,
    showViewJourneyButton,
  ]);
  useEffect(() => {
    sessionStorage.setItem('emailCheckResult', JSON.stringify(emailCheckResult));
  }, [emailCheckResult]);

  useLayoutEffect(() => {
    GetQuestionSet({ setQuestions });
  }, []);

  useEffect(() => {
    if (redirect === "Journey-to-Home") {
      setCurrentStep(0);
      setIsFormSubmitted(false);
      window.history.replaceState({}, "");
    }
  }, [redirect]);

  const togglePrivacyPolicyDialog = () => {
    setPrivacyPolicyDialogOpen(!privacyPolicyDialogOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await UpdateFormData(formData, selectedFile, questions);
      setBackendMessage(response);
      setIsSnackbarOpen(true);
      setIsFormSubmitted(true);
      setTimeout(() => {
        setIsJourneyInProgress(true);
        setShowViewJourneyButton(true); // Show the view journey button after 10 seconds
      });
      // Call CheckEmail API after UpdateFormData
      const emailCheckResult = await CheckEmail(formData.email);


      const { journeyExist, emailIdPresent, editAllowed } = emailCheckResult;

      setEmailCheckResult({ journeyExist, emailIdPresent, editAllowed });
    } catch (error) {
      // Handle error
      console.error("Error updating form data:", error);
    }
  };

  const fetchMyName = () => {
    if (
      sessionStorage.getItem("AccessToken") !== null &&
      sessionStorage.getItem("AccessToken") !== undefined
    ) {
      FetchUserInfo({ setUserName });
    }
  };

  useEffect(() => {

    if (!isJourneyCreated) {

      setJourneyInterval(
        setInterval(() => {

          FetchhtmlJourney({ setHtmlJourney });

          setTimeout(() => {

            if (!isJourneyCreated) {

              setIsJourneyCreated(true);
            }
          }, 50000);
        }, 50000)
      );
    } else {

      clearInterval(journeyInterval);
    }
  }, [isJourneyCreated]);

  const handleMyJourneyButtonClick = () => {

    if (isJourneyCreated) {

      navigate('/myJourney');
    }
  };
  useEffect(() => {
    fetchMyName();
    FetchhtmlJourney({ setHtmlJourney });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.querySelectorAll("p");
      if (paragraphs.length > 0) {
        const lastParagraph = paragraphs[paragraphs.length - 1];
        lastParagraph.style.fontStyle = "italic";
      }
    }
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFeedbackButtonClick = () => {
    const feedbackFormUrl = "https://forms.office.com/r/sXMxBpWsu0"; // Replace this with your feedback form URL
    window.open(feedbackFormUrl, "_blank");
  };

  const onMainLogoClick = async () => {
    setCurrentStep(0);
    setIsFormSubmitted(false);
    try {
      const result = await CheckEmail(formData.email);
      const { journeyExist, emailIdPresent, editAllowed } = result;
      setEmailCheckResult({ journeyExist, emailIdPresent, editAllowed });

      // Update session storage with emailCheckResult
      sessionStorage.setItem('emailCheckResult', JSON.stringify({ journeyExist, emailIdPresent, editAllowed }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleHomeButtonClick = () => {
    // Add your home button click handling logic here
    window.location.href = "/";
  };

  const handleSignOut = () => {
    setIsSignedIn(false);
    setUserName("");
    setCurrentStep(0);

    setIsEditing(false);
    setFormData({});
    setDraftData({});
    setIsFormSubmitted(false);
    setIsJourneyInProgress(false);
    setShowViewJourneyButton(false);

    // Clear session and local storage
    sessionStorage.clear();
    localStorage.clear();

    handleMenuClose();
    navigate('/'); // Redirect to home page or login page
  };

  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Header
          userName={userName}
          isSignedIn={isSignedIn}
          onSignOut={handleSignOut}
          onMainLogoClick={onMainLogoClick}
        />
        {currentStep !== 0 && (
          <Container className="text-header-container">
            <Typography variant="h4" className="text-4xl text-white">
              SkillJourney Questionnaire
            </Typography>
          </Container>
        )}
        {isSignedIn ? (
          <form onSubmit={handleSubmit}>
            {isFormSubmitted ? (
              <Typography variant="h5" className="text-center mt-8">
                Thank you for submitting your answers. You will receive your
                Career Journey on the email you have provided. In case of any
                questions, please contact{" "}
                <a
                  href="mailto:support@skilljourney.in"
                  style={{ color: "#007bff", textDecoration: "none" }}
                >
                  support@skilljourney.in
                </a>
                .
                {isJourneyInProgress && (
                  <Typography variant="h5" className="text-center mt-4">
                    Your Career Journey creation is in progress. It usually
                    takes up to 10 mins for the journey to be generated. Please
                    check after some time.
                  </Typography>
                )}
                {showViewJourneyButton && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleMyJourneyButtonClick}
                      disabled={!isJourneyCreated}
                    >
                      My Journey
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFeedbackButtonClick}
                      className="mt-4"
                      style={{ marginLeft: "10px" }}
                    >
                      FEEDBACK
                    </Button>
                  </>
                )}
              </Typography>
            ) : (
              <>
                <RenderStep
                  formData={formData}
                  currentStep={currentStep}
                  setFormData={setFormData}
                  questions={questions}
                  isEditing={isEditing}
                  setDraftData={setDraftData}
                />
                {currentStep === 9 && (
                  <input
                    type="file"
                    className="file-input"
                    onChange={handleFileChange}
                  />
                )}
                <RenderButtons
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  formData={formData}
                  setIsEditing={setIsEditing}
                  draftData={draftData}
                  setDraftData={setDraftData}
                  questions={questions}
                />
              </>
            )}
          </form>
        ) : (
          <Box mt={2} pb={44}>
            <GoogleLoginButton onSuccess={() => setIsSignedIn(true)} />
          </Box>
        )}
        <div className=" p-2">
          <Footer />
        </div>
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleHomeButtonClick}>Sign Out</MenuItem>
      </Menu>
      <SnackbarComponent isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} backendMessage={backendMessage} />
      <PrivacyPolicyDialog
        open={privacyPolicyDialogOpen}
        onClose={togglePrivacyPolicyDialog}
      />
      <PrivacyPolicyDialog
        open={privacyPolicyDialogOpen}
        onClose={togglePrivacyPolicyDialog}
      />
    </React.Fragment>
  );
}

export default ReactForm;
