import { Grid } from "@mui/material";
import CheckBox from "../boxes/CheckBox";
import RadioBox from "../boxes/RadioBox";
import { TextBox } from "../boxes/TextBox";
import { useState, useEffect } from "react";

export const DynamicContainer = (props) => {
    const [required, setRequired] = useState(false);

    useEffect(() => {
        if (props.answeredQuestionArray && props.answeredQuestionArray.questions) {
            const initialFormData = { ...props.formData };
            props.answeredQuestionArray.questions.forEach(question => {
                const questionName = props.questionArray.fields.find(field => field.id === question.id).name;
                if (question.question.includes("What kind of work environment brings out your best performance? (Select min 1, max 3)")) {
                    if (!Array.isArray(initialFormData[questionName])) {
                        initialFormData[questionName] = question.reply;
                    }
                } else {
                    initialFormData[questionName] = question.reply;
                }
            });
            props.setFormData(initialFormData);
        }
    }, [props.answeredQuestionArray]);

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        const updatedWorkEnv = Array.isArray(props.formData.workEnv) ? [...props.formData.workEnv] : []; // Initialize as empty array if undefined
    
        // Check if the value is already in the array
        const index = updatedWorkEnv.indexOf(value);
    
        if (index !== -1) {
            // Value is already selected, so deselect it
            updatedWorkEnv.splice(index, 1);
        } else {
            if (updatedWorkEnv.length < 3) {
                // Value is not selected, so select it
                updatedWorkEnv.push(value);
            } else {
                alert("You can select a maximum of 3 options.");
                return; // Prevent further execution if validation fails
            }
        }
    
        if (required && updatedWorkEnv.length === 0) {
            alert("At least one checkbox is required.");
            return; // Prevent further execution if validation fails
        }
    
        props.setDraftData(prevData => ({ ...prevData, workEnv: updatedWorkEnv }));
        props.setFormData({ ...props.formData, workEnv: updatedWorkEnv });
    };
    
     const handleRadioButtonChange = (e) => {
        const { name, value } = e.target;
        
        if (name === "contactNumber") {
            // Validate contact number format
            if (!/^\+?\d{0,15}$/.test(value)) {
                alert("Contact number should contain only numerical values and may include a leading '+' sign. It should be a maximum of 15 digits long.");
                return;
            }
        }
        
        // Update state with the new value
        props.setDraftData(prevData => ({ ...prevData, [name]: value }));
        props.setFormData(prevData => ({ ...prevData, [name]: value }));
    };
    


    const getAnswer = (id) => {
        if (props.answeredQuestionArray !== undefined) {    
            const answeredQuestion = props.answeredQuestionArray.questions.find(field => field.id === id);
            return answeredQuestion ? answeredQuestion.reply : null;
        } else {
            return null;
        }
    };

    return (
        <>
            <Grid container spacing={5}>
                <div className="text-center">
                    <div className="my-5 border border-gray-300 p-6 inline-block section-header">
                        <h4 className="font-serif text-2xl">{props.questionArray.title}</h4>
                    </div>
                </div>
            </Grid>
            {props.questionArray.fields.map((question, index) => (
                <div key={index}>
                    {(question.type === "text" || question.type === "email") && (
                        <TextBox
                            step={props.step}
                            question={question}
                            index={index}
                            handleRadioButtonChange={handleRadioButtonChange}
                            formData={props.formData}
                            answer={getAnswer(question.id)} // Pass answered array
                        />
                    )}
                    {question.type === "radio" && (
                        <RadioBox
                            step={props.step}
                            question={question}
                            index={index}
                            handleRadioButtonChange={handleRadioButtonChange}
                            formData={props.formData}
                            answer={getAnswer(question.id)} // Pass answered array
                        />
                    )}
                    {question.type === "checkbox" && (
                        <CheckBox
                            step={props.step}
                            question={question}
                            index={index}
                            handleCheckboxChange={handleCheckboxChange}
                            formData={props.formData}
                            setRequired={setRequired}
                            answer={getAnswer(question.id)} // Pass answered array
                        />
                    )}
                </div>
            ))}
        </>
    );
};
