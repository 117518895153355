import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import Header from '../../../header';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../footer/footer';

export const AboutUs = () => {
    const navigate = useNavigate();

    return (<React.Fragment>
        <Container maxWidth="md">
            <Header onMainLogoClick={() => navigate('/')} />
            <Container style={{ marginTop: '3rem', paddingBottom: '10rem' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Box textAlign="center" mb={2}>
                        <Typography variant="h4" gutterBottom>
                            About Us
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        Welcome to our company! We are dedicated to providing the best service possible.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our mission is to create value for our customers through innovation and excellence.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We believe in building strong relationships with our clients and partners.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Thank you for choosing us. We look forward to working with you.
                    </Typography>
                </Paper>
            </Container>
            <div className=" p-2">
                <Footer />
            </div>
        </Container>
    </React.Fragment>
    );
};

