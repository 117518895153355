import React, { useEffect, useRef, useState } from "react";
import { Container, Typography, Button } from "@mui/material";
import { FetchUserInfo, FetchhtmlJourney } from "../../../api/FetchhtmlJourney";
import Header from '../../../header'; // Correct path to Header component
import "./myJourney.css";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import { CheckEmail } from "../../../api/CheckEmail"; // Import CheckEmail function
import { RazorpayButton } from "../../buttons/RazorpayButton";
import { Footer } from "../../footer/footer";

export const MyJourney = () => {
  const [htmlJourney, setHtmlJourney] = useState({});
  const containerRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true); // Assuming user is signed in
  const navigate = useNavigate(); // useHistory hook from react-router-dom
  const [currentStep, setCurrentStep] = useState(
    Number(sessionStorage.getItem("currentStep")) || 0
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(
    JSON.parse(sessionStorage.getItem("isFormSubmitted")) || false
  );
  const fetchMyName = () => {
    if (sessionStorage.getItem("AccessToken")) {
      FetchUserInfo({ setUserName });
    }
  };
  // State to store email check results
  const [emailCheckResult, setEmailCheckResult] = useState({
    journeyExist: false,
    emailIdPresent: false,
    editAllowed: false,
  });
  useEffect(() => {
    fetchMyName();
    FetchhtmlJourney({ setHtmlJourney }).then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.querySelectorAll("p");
      if (paragraphs.length > 0) {
        const lastParagraph = paragraphs[paragraphs.length - 1];
        lastParagraph.style.fontStyle = "italic";
      }
    }
  }, [htmlJourney]);
  useEffect(() => {
    sessionStorage.setItem('emailCheckResult', JSON.stringify(emailCheckResult));
  }, [emailCheckResult]);
  const handleFeedbackButtonClick = () => {
    const feedbackFormUrl = "https://forms.office.com/r/sXMxBpWsu0";
    window.open(feedbackFormUrl, "_blank");
  };



  const handleSignOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/'); // Redirect to home page or login page
  };

  const onMainLogoClick = async () => {
    setCurrentStep(0);
    setIsFormSubmitted(false);
    try {
      const result = await CheckEmail(sessionStorage.getItem('Email'));
      const { journeyExist, emailIdPresent, editAllowed } = result;
      setEmailCheckResult({ journeyExist, emailIdPresent, editAllowed });

      // Update session storage with emailCheckResult
      sessionStorage.setItem('emailCheckResult', JSON.stringify({ journeyExist, emailIdPresent, editAllowed }));
    } catch (error) {
      console.error(error);
    }
    navigate('/', { state: { redirect: 'Journey-to-Home' } });
  };
  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Header userName={userName} isSignedIn={isSignedIn} onSignOut={handleSignOut} onMainLogoClick={onMainLogoClick} />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography variant="h4" className="text-4xl text-white">
            Career Journey for {userName}
          </Typography>
        </Container>
        {isLoading ? (
          <div className="text-center mt-4">
            <Typography variant="body1" className="mt-2">
              Your Journey is being created...
            </Typography>
          </div>
        ) : (
          <div className="input-container" ref={containerRef}>
            <div dangerouslySetInnerHTML={{ __html: htmlJourney.career_journey }} />
          </div>
        )}
        <div className="flex justify-between pb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={handleFeedbackButtonClick}
            className="mt-4 ml-4"
          >
            FEEDBACK
          </Button>
          <RazorpayButton />
        </div>
        <div className=" p-2">
          <Footer />
        </div>
      </Container>
    </React.Fragment>
  );
};
