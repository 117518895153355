import { BackendApi } from "./config/ApiFormatter";

export const UpdateFormData = async (formData, file, questions) => {
    const uploadUrl = BackendApi() + "updateUserQuestionnaire";
    const idToken = sessionStorage.getItem('AccessToken'); // Fetch the ID token from session storage
  
    const data = {
      username: sessionStorage.getItem('Email'),
      questionnaireComplete: true,
      questionnaire: constructQuestionnaire(formData, questions)
    };
  
    const formDataObj = new FormData();
    formDataObj.append('file', file);
    formDataObj.append('data', JSON.stringify(data));
  
    const response = await fetch(uploadUrl, {
      method: 'PUT', // Use PUT method instead of POST
      headers: {
        'Authorization': `Bearer ${idToken}` 
      },
      body: formDataObj
    });
  
    const jsonResponse = await response.json();
   
    
    // Assuming your API responds with a message or error field
    return response.ok ? jsonResponse.message : jsonResponse.error;
  };
  
  const constructQuestionnaire = (formData, questions) => {
    return questions.map((section, key) => {
      const intId = key + 1;
      return {
        sectionId: intId,
        title: section.title,
        questions: section.fields.map((question, idx) => {
          const reply = formData[question.name];
          const questionLabel = question.label;
          const id = `${intId}.${idx + 1}`;
          return {
            id: id,
            question: questionLabel,
            reply: reply,
          };
        })
      };
    });
  };
  