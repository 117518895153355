import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import Header from '../../../header';
import { useNavigate } from 'react-router-dom';
import { PrivacyContent } from './privacyContent';
import { Footer } from '../../footer/footer';

export const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (<React.Fragment>
        <Container >
            <Container maxWidth="md">
                <Header onMainLogoClick={() => navigate('/')} /></Container>
            <Container style={{ marginTop: '3rem', marginBottom: '2rem', alignItems: 'center' }}>
                <Paper elevation={3} style={{ padding: '20px', width: '70rem' }}>
                    <Box textAlign="center" mb={2}>
                        <Typography variant="h4" gutterBottom>
                            Privacy Policy
                        </Typography>
                    </Box>
                    <PrivacyContent />
                </Paper>
            </Container>
            <div className=" p-2">
                <Footer />
            </div>
        </Container>
    </React.Fragment>
    );
};

